import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-fees-collection',
  templateUrl: './fees-collection.component.html',
  styleUrls: ['./fees-collection.component.scss']
})
export class FeesCollectionComponent implements OnInit {
  add: any = {};
  public btndisable: boolean = false;
  public addValidation: boolean = false;
  headers;

  public Course_Rows = [];
  public Student_Rows = [];
  public Collection_Rows = [];
  public s_gender = [{id:1,text:'Male'},{id:2,text:'Female'},{id:3,text:'Others'}];
  selectedOption: any;

  public ID :any={}
  isadd="0";

  constructor(private _location: Location,public confirmationService: ConfirmationService,public appservice: AppService, private toastr: ToastrService, private http: HttpClient, private router: Router, private route: ActivatedRoute) { 
 // this.s_father = this.appservice.Reference_Rows.filter(e => e.Ref_ID == "Father");
 this.get_Course(); 
 this.get_fees_collection();
 this.get_feescollection_Details();
 //this.get_gender();
 // this.isadd = appservice.isadd;
 // if (this.isadd == "0") {
 //   this.add.s_id = "0";
 // }
 // else {
 //   this.add = appservice.Edit_Row;
 // }

  }
  get_Course() {
    this.appservice.getc("Api/Master/get_course").subscribe((res: any) => {
      this.Course_Rows = JSON.parse(res).record;
    });
  }

  get_fees_collection() {
    debugger
     this.ID = localStorage.getItem('User_Data');
    this.appservice.get("Api/transaction/get_fees_collection?StudentId="+this.appservice.CREATED_BY).subscribe((res: any) => {
      this.Student_Rows = JSON.parse(res).record;
      this.add=this.Student_Rows[0];
    });
  }

  get_feescollection_Details() {
    debugger
     this.ID = localStorage.getItem('User_Data');
    this.appservice.get("Api/transaction/get_fees_collection?StudentId="+this.appservice.CREATED_BY).subscribe((res: any) => {
      this.Collection_Rows = JSON.parse(res).record;
      this.add=this.Collection_Rows[0];
    });
  }

  Valid;

  addData(f) {
    this.addValidation = false;
    this.btndisable=true;
    //this.add.pm_type="Product";
    this.add.Company = this.appservice.Company;
    this.add.Created_by = this.appservice.CREATED_BY;    
    this.add.ID=this.add.s_id;
    //this.add.pm_item_group="1";
    this.add.Table_Name="student_master";
    this.add.ColumnPerfix="s_";

    this.headers = new Headers({ 'Content-Type': 'application/json; charset=utf-8' });
    this.http.post(this.appservice.Server_URL + 'api/Master/Post_Data', this.add, { headers: this.headers })
      .subscribe(
        
        (val: string) => {
          this.btndisable=false;

          if (val == "True") {
            this.toastr.success("Details Saved Success", 'Msg');
            //this.appservice.get_User_Master();
            this.Clear();
            if (this.isadd != "0") {
              this._location.back();
            }
          }
          else {
            this.toastr.error(val, "Error", { timeOut: 3000 });
          }

        },
        response => {
          this.toastr.error('Error ', response, {
            timeOut: 3000
          });
        });
  }
  Back()
  {
    this._location.back();
  }
  Clear() {
    this.add =
    {
      'PM_id': '',
      'pm_brand': '',
      'pm_Group': '',
      'pm_name': '',
      'pm_Code': '',
      'pm_hsncode': '',
      'pm_UOM': '',
      'pm_nqty': '',
      'pm_GST': '',
      'pm_mrpprice': '',
      'pm_purprice': '',
    };
  }
  ngOnInit(): void {
  }

}
