<div class="row">
    <div class="col-md-12 nopad">
        <div class="card content c_pad_5">
            <div class=" Head  mhide">
                <div class="card-header  d-flex justify-content-between align-items-center">
                    <h3>Fees Collection</h3>
  
                    <div class="dropdown">
  
                        <a (click)="appservice.back()" class="x">
                            <b>X</b>
                        </a>
  
  
                    </div>
                </div>
  
            </div>
  
            <div class="content_body">
  
  
  
                <form #addForm="ngForm" (ngSubmit)="addData(addForm)" (keydown.enter)="$event.preventDefault()"
                    novalidate>
  
                    <div class="row">
                        <div class="col-sm-6">
  
                            <div class="form-table table-responsive">
                                <table class="table table-bordered">
                                    <tbody>
                                        <tr class="hidden">
                                            <td>ID <span class="text-danger"></span></td>
                                            <td><input class="form-control" type="text" [(ngModel)]="add.s_id" name="s_id"
                                                    #s_id="ngModel" ></td>
                                        </tr>
                                       <!--  <tr>
                                            <td>Academic Year<span class="text-danger"></span></td>
                                            <td><input class="form-control" type="text" [(ngModel)]="add.s_regno"required name="s_regno"
                                                    #s_regno="ngModel" >
                                                </td>
                                        </tr> -->
                                        <tr>
                                            <td>Academic Year <span class="text-danger"></span></td>
                                            <td><input class="form-control" type="text" [(ngModel)]="add.s_academicyear" name="s_academicyear"
                                                    #s_academicyear="ngModel" ></td>
                                        </tr>
                                        <tr>
                                            <td>Course<span class="text-danger"></span></td>
                                            <td>
                                                <input class="form-control" type="text" [(ngModel)]="add.s_course" name="s_course"
                                                #s_course="ngModel" > 
                                                   <!--  <select class="select2-option form-control "  [(ngModel)]="add.s_course" name="s_course" #s_course="ngModel" style="width:100%"   aria-hidden="true">
                    
                                                        <option *ngFor="let summary of Course_Rows" value={{summary.label}}>
                                                            {{summary.label}}
                                                        </option>
                                                    </select> -->
                                                </td>
                                        </tr>
                                        <tr>
                                          <td>Student<span class="text-danger"></span></td>
                                          <td><input class="form-control" type="text" [(ngModel)]="add.s_studentname"required name="s_studentname"
                                                  #s_studentname="ngModel" >
                                              </td>
                                      </tr>
                                      <tr >
                                          <td>Father<span class="text-danger"></span>
                                          </td>
                                          <td>
                                            <input class="form-control" type="text" [(ngModel)]="add.s_father"required name="s_father"
                                            #s_father="ngModel" >
                                          </td>
                                      </tr>
                                     
                                    <!--   <tr >
                                          <td>Father <span  class="text-danger"></span>
                                          </td>
                                          <td>
                                              <input class="form-control" type="text"  [(ngModel)]="add.s_father" name="s_father" #s_father="ngModel" >
                    
                                   
                                             
                                          </td>
                                      </tr> -->
                                     <!--  <tr >
                                        <td> Adm.No<span  class="text-danger"></span>
                                        </td>
                                        <td>
                                                <input class="form-control" type="text" [(ngModel)]="add.s_admno"required name="s_admno"
                                            #s_admno="ngModel" >
                                        </td>
                                    </tr>
                                    <tr >
                                        <td> Add.Fees<span  class="text-danger"></span>
                                        </td>
                                        <td>
                                                <input class="form-control" type="text" [(ngModel)]="add.s_addfees"required name="s_addfees"
                                            #s_addfees="ngModel" >
                                        </td>
                                    </tr>
                                    <tr >
                                        <td> Bus<span  class="text-danger"></span>
                                        </td>
                                        <td>
                                                <input class="form-control" type="text" [(ngModel)]="add.s_bus"required name="s_bus"
                                            #s_bus="ngModel" >
                                        </td>
                                    </tr> -->
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="col-sm-6">
  
                          <div class="form-table table-responsive">
                              <table class="table table-bordered">
                                  <tbody>
                                      <tr >
                                          <td> Bill No <span  class="text-danger"></span>
                                          </td>
                                          <td>
                                              <input class="form-control" type="text" value="0" [(ngModel)]="add.Fees_RcNo"required name="Fees_RcNo"
                                              #Fees_RcNo="ngModel" >
                                          </td>
                                      </tr>
                                      <tr>
                                          <td>Semester <span  class="text-danger"></span>
                                          </td>
                                          <td>
                                                      <input class="form-control" type="text" value="0" [(ngModel)]="add.sf_sem"required name="sf_sem"
                                                      #sf_sem="ngModel" >
                                          </td>
                                      </tr>
                                      <tr >
                                          <td> RegNo <span  class="text-danger"></span>
                                          </td>
                                          <td>
                                            <input class="form-control" type="text" value="0" [(ngModel)]="add.s_regno"required name="s_regno"
                                            #s_regno="ngModel" >
                          
                                          </td>
                                      </tr>
                                     <!--  <tr>
                                          <td>DOB <span class="text-danger"></span></td>
                                          <td><input class="form-control" type="text" value="0" [(ngModel)]="add.s_dob"required name="s_dob"
                                               #s_dob="ngModel" >
                                      </td>
                                      </tr> -->
                                    <!--   <tr>
                                          <td>Community<span class="text-danger"></span></td>
                                          <td><input class="form-control" type="text" value="0" [(ngModel)]="add.s_community"required name="s_community"
                                                  #s_community="ngModel" >
                                              </td>
                                      </tr>
                                      <tr>
                                        <td>Bus No<span class="text-danger"></span></td>
                                        <td><input class="form-control" type="number" value="0" [(ngModel)]="add.s_busid"required name="s_busid"
                                                #s_busid="ngModel" >
                                            </td>
                                    </tr>
                                    <tr>
                                        <td>Bus Stage<span class="text-danger"></span></td>
                                        <td><input class="form-control" type="text" value="0" [(ngModel)]="add.s_community"required name="s_community"
                                                #s_community="ngModel" >
                                            </td>
                                    </tr> -->
                                  </tbody>
                              </table>
                          </div>
                          
                      </div>
                      <br><br>
                         <!--  <div class="col-sm-6">
                         
                              <div class="invoice-btn-box text-right " style="padding-bottom:20px">
  
                                  <button class="btn btn-info rounded-pil" [disabled]="btndisable" type="submit">
   
                                      <span *ngIf="btndisable==true" class="spinner-border spinner-border-sm"
                                          role="status" aria-hidden="true"></span>
                                      <i *ngIf="btndisable==false" class='bx bx-paper-plane'></i>
                                      Save </button> &nbsp;&nbsp;
                                  <button class="btn btn-secondary rounded-pil " type="button"
                                      (click)="appservice.back()">Cancel</button> &nbsp;&nbsp;
                              </div>
  
                              </div> -->
                    </div>
                    <div class="row">
                        <div class="col-sm-2">
                            <div class="form-table table-responsive">
                                <table class="table table-bordered">
                                    <tbody>
                            <tr >
                                <td> 
                                </td>
                              
                                <td>
                                    Fees<span  class="text-danger"></span>
                                </td>
                                
                            </tr> 
                        </tbody>
                    </table>
                </div>
                            </div>
                            <div class="col-sm-2">
                                <div class="form-table table-responsive">
                                    <table class="table table-bordered">
                                        <tbody>
                                                               
                                    <td>
                                        Cons.Paid<span  class="text-danger"></span>
                                    </td>
                                </tbody>
                            </table>
                        </div>
                                </div>
                                <div class="col-sm-2">
                                    <div class="form-table table-responsive">
                                        <table class="table table-bordered">
                                            <tbody>
                                                               
                                    <td>
                                        Cons<span  class="text-danger"></span>
                                    </td>
                                </tbody>
                            </table>
                        </div>
                                </div>
                                <div class="col-sm-2">
                                    <div class="form-table table-responsive">
                                        <table class="table table-bordered">
                                            <tbody>
                                                               
                                    <td>
                                        Paid<span  class="text-danger"></span>
                                    </td>
                                </tbody>
                            </table>
                        </div>
                                </div>
                                <div class="col-sm-2">                            
                                    <div class="form-table table-responsive">
                                        <table class="table table-bordered">
                                            <tbody>                         
                                    <td>
                                        Balance<span  class="text-danger"></span>
                                    </td>
                                </tbody>
                            </table>
                        </div>
                                </div>
                                <div class="col-sm-2">
                                    <div class="form-table table-responsive">
                                        <table class="table table-bordered">
                                            <tbody>                                                      
                                    <td>
                                        Pay<span  class="text-danger"></span>
                                    </td>
                                </tbody>
                            </table>
                        </div>
                                </div>
                                
                        </div>
                        <div class="row">
                            <div class="col-sm-2">
                                <div class="form-table table-responsive">
                                    <table class="table table-bordered">
                                        <tbody>
                                <tr >
                                    <td> Course<span  class="text-danger"></span>
                                    </td>
                                  
                                    <td>
                                            <input class="form-control" type="text" [(ngModel)]="add.s_addr"required name="s_addr"
                                        #s_addr="ngModel" >
                                    </td>
                                    
                                </tr> 
                            </tbody>
                        </table>
                    </div>
                                </div>
                                <div class="col-sm-2">
                                    <div class="form-table table-responsive">
                                        <table class="table table-bordered">
                                            <tbody>
                                                                   
                                        <td>
                                                <input class="form-control" type="text" [(ngModel)]="add.Course_Fees"required name="Course_Fees"
                                            #Course_Fees="ngModel" >
                                        </td>
                                    </tbody>
                                </table>
                            </div>
                                    </div>
                                    <div class="col-sm-2">
                                        <div class="form-table table-responsive">
                                            <table class="table table-bordered">
                                                <tbody>
                                                                   
                                        <td>
                                                <input class="form-control" type="text" [(ngModel)]="add.Course_CN"required name="Course_CN"
                                            #Course_CN="ngModel" >
                                        </td>
                                    </tbody>
                                </table>
                            </div>
                                    </div>
                                    <div class="col-sm-2">
                                        <div class="form-table table-responsive">
                                            <table class="table table-bordered">
                                                <tbody>
                                                                   
                                        <td>
                                                <input class="form-control" type="text" [(ngModel)]="add.s_addr"required name="s_addr"
                                            #s_addr="ngModel" >
                                        </td>
                                    </tbody>
                                </table>
                            </div>
                                    </div>
                                    <div class="col-sm-2">                            
                                        <div class="form-table table-responsive">
                                            <table class="table table-bordered">
                                                <tbody>                        
                                        <td>
                                                <input class="form-control" type="text" [(ngModel)]="add.Course_Fees"required name="Course_Fees"
                                            #Course_Fees="ngModel" >
                                        </td>
                                    </tbody>
                                </table>
                            </div>
                                    </div>
                                    <div class="col-sm-2">
                                        <div class="form-table table-responsive">
                                            <table class="table table-bordered">
                                                <tbody>                                                        
                                        <td>
                                                <input class="form-control" type="text" [(ngModel)]="add.s_addr"required name="s_addr"
                                            #s_addr="ngModel" >
                                        </td>
                                    </tbody>
                                </table>
                            </div>
                                    </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-2">
                                    <div class="form-table table-responsive">
                                        <table class="table table-bordered">
                                            <tbody>
                                    <tr >
                                        <td> App<span  class="text-danger"></span>
                                        </td>
                                      
                                        <td>
                                                <input class="form-control" type="text" [(ngModel)]="add.SF_appfees"required name="SF_appfees"
                                            #SF_appfees="ngModel" >
                                        </td>
                                        
                                    </tr> 
                                </tbody>
                            </table>
                        </div>
                                    </div>
                                    <div class="col-sm-2">
                                        <div class="form-table table-responsive">
                                            <table class="table table-bordered">
                                                <tbody>
                                                                       
                                            <td>
                                                    <input class="form-control" type="text" [(ngModel)]="add.App_CN"required name="App_CN"
                                                #App_CN="ngModel" >
                                            </td>
                                        </tbody>
                                    </table>
                                </div>
                                        </div>
                                        <div class="col-sm-2">
                                            <div class="form-table table-responsive">
                                                <table class="table table-bordered">
                                                    <tbody>
                                                                       
                                            <td>
                                                    <input class="form-control" type="text" [(ngModel)]="add.s_addr"required name="s_addr"
                                                #s_addr="ngModel" >
                                            </td>
                                        </tbody>
                                    </table>
                                </div>
                                        </div>
                                        <div class="col-sm-2">
                                            <div class="form-table table-responsive">
                                                <table class="table table-bordered">
                                                    <tbody>
                                                                       
                                            <td>
                                                    <input class="form-control" type="text" [(ngModel)]="add.App_fees"required name="App_fees"
                                                #App_fees="ngModel" >
                                            </td>
                                        </tbody>
                                    </table>
                                </div>
                                        </div>
                                        <div class="col-sm-2">                            
                                            <div class="form-table table-responsive">
                                                <table class="table table-bordered">
                                                    <tbody>                          
                                            <td>
                                                    <input class="form-control" type="text" [(ngModel)]="add.s_addr"required name="s_addr"
                                                #s_addr="ngModel" >
                                            </td>
                                        </tbody>
                                    </table>
                                </div>
                                        </div>
                                        <div class="col-sm-2">
                                            <div class="form-table table-responsive">
                                                <table class="table table-bordered">
                                                    <tbody>                                                       
                                            <td>
                                                    <input class="form-control" type="text" [(ngModel)]="add.s_addr"required name="s_addr"
                                                #s_addr="ngModel" >
                                            </td>
                                        </tbody>
                                    </table>
                                </div>
                                        </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-2">
                                        <div class="form-table table-responsive">
                                            <table class="table table-bordered">
                                                <tbody>
                                        <tr >
                                            <td> Book<span  class="text-danger"></span>
                                            </td>                                          
                                            <td>
                                                    <input class="form-control" type="text" [(ngModel)]="add.sf_bookfees"required name="sf_bookfees"
                                                #sf_bookfees="ngModel" >
                                            </td>                                            
                                        </tr> 
                                    </tbody>
                                </table>
                            </div>
                                        </div>
                                        <div class="col-sm-2">   
                                            <div class="form-table table-responsive">
                                                <table class="table table-bordered">
                                                    <tbody>                                                                                                                
                                                <td>
                                                        <input class="form-control" type="text" [(ngModel)]="add.Book_CN"required name="Book_CN"
                                                    #Book_CN="ngModel" >
                                                </td> 
                                            </tbody>
                                        </table>
                                    </div>                                    
                                            </div>
                                            <div class="col-sm-2">  
                                                <div class="form-table table-responsive">
                                                    <table class="table table-bordered">
                                                        <tbody>                                                                                                                 
                                                <td>
                                                        <input class="form-control" type="text" [(ngModel)]="add.s_addr"required name="s_addr"
                                                    #s_addr="ngModel" >
                                               </td> 
                                            </tbody>
                                        </table>
                                    </div>                                    
                                            </div>
                                            <div class="col-sm-2">    
                                                <div class="form-table table-responsive">
                                                    <table class="table table-bordered">
                                                        <tbody>                                                                                                              
                                                <td>
                                                        <input class="form-control" type="text" [(ngModel)]="add.Book_fees"required name="Book_fees"
                                                    #Book_fees="ngModel" >
                                               </td>  
                                            </tbody>
                                        </table>
                                    </div>                                   
                                            </div>
                                            <div class="col-sm-2">  
                                                <div class="form-table table-responsive">
                                                    <table class="table table-bordered">
                                                        <tbody>                                                                                                    
                                                <td>
                                                        <input class="form-control" type="text" [(ngModel)]="add.s_addr"required name="s_addr"
                                                    #s_addr="ngModel" >
                                                </td> 
                                            </tbody>
                                        </table>
                                    </div>                                    
                                            </div>
                                            <div class="col-sm-2">  
                                                <div class="form-table table-responsive">
                                                    <table class="table table-bordered">
                                                        <tbody>                                                                                                     
                                                <td>
                                                        <input class="form-control" type="text" [(ngModel)]="add.s_addr"required name="s_addr"
                                                    #s_addr="ngModel" >
                                                </td>  
                                            </tbody>
                                        </table>
                                    </div>                                   
                                            </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-2">
                                            <div class="form-table table-responsive">
                                                <table class="table table-bordered">
                                                    <tbody>
                                            <tr >
                                                <td> Bus<span  class="text-danger"></span>
                                                </td>                                              
                                                <td>
                                                        <input class="form-control" type="text" [(ngModel)]="add.SF_busfees"required name="SF_busfees"
                                                    #SF_busfees="ngModel" >
                                                </td>                                                
                                            </tr> 
                                        </tbody>
                                    </table>
                                </div>
                                            </div>
                                            <div class="col-sm-2">  
                                                <div class="form-table table-responsive">
                                                    <table class="table table-bordered">
                                                        <tbody>                                                                                                                        
                                                    <td>
                                                            <input class="form-control" type="text" [(ngModel)]="add.Bus_CN"required name="Bus_CN"
                                                        #Bus_CN="ngModel" >
                                                    </td> 
                                                </tbody>
                                            </table>
                                        </div>                                         
                                                </div>
                                                <div class="col-sm-2"> 
                                                    <div class="form-table table-responsive">
                                                        <table class="table table-bordered">
                                                            <tbody>                                                                                                                          
                                                    <td>
                                                            <input class="form-control" type="text" [(ngModel)]="add.s_addr"required name="s_addr"
                                                        #s_addr="ngModel" >
                                                    </td>  
                                                </tbody>
                                            </table>
                                        </div>                                       
                                                </div>
                                                <div class="col-sm-2">  
                                                    <div class="form-table table-responsive">
                                                        <table class="table table-bordered">
                                                            <tbody>                                                                                                                          
                                                    <td>
                                                            <input class="form-control" type="text" [(ngModel)]="add.Bus_fees"required name="Bus_fees"
                                                        #Bus_fees="ngModel" >
                                                    </td>
                                                </tbody>
                                            </table>
                                        </div>
                                                </div>
                                                <div class="col-sm-2">                            
                                                    <div class="form-table table-responsive">
                                                        <table class="table table-bordered">
                                                            <tbody>                         
                                                    <td>
                                                            <input class="form-control" type="text" [(ngModel)]="add.s_addr"required name="s_addr"
                                                        #s_addr="ngModel" >
                                                    </td>
                                                </tbody>
                                            </table>
                                        </div>
                                                </div>
                                                <div class="col-sm-2">
                                                    <div class="form-table table-responsive">
                                                        <table class="table table-bordered">
                                                            <tbody>                                                     
                                                    <td>
                                                            <input class="form-control" type="text" [(ngModel)]="add.s_addr"required name="s_addr"
                                                        #s_addr="ngModel" >
                                                    </td>
                                                </tbody>
                                            </table>
                                        </div>
                                                </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-2">
                                                <div class="form-table table-responsive">
                                                    <table class="table table-bordered">
                                                        <tbody>
                                                <tr >
                                                    <td> Total<span  class="text-danger"></span>
                                                    </td>
                                                  
                                                    <td>
                                                            <input class="form-control" type="text" [(ngModel)]="add.s_addr"required name="s_addr"
                                                        #s_addr="ngModel" >
                                                    </td>
                                                    
                                                </tr> 
                                            </tbody>
                                        </table>
                                    </div>
                                                </div>
                                                <div class="col-sm-2">
                                                    <div class="form-table table-responsive">
                                                        <table class="table table-bordered">
                                                            <tbody>
                                                                                   
                                                        <td>
                                                                <input class="form-control" type="text" [(ngModel)]="add.s_addr"required name="s_addr"
                                                            #s_addr="ngModel" >
                                                        </td>
                                                    </tbody>
                                                </table>
                                            </div>
                                                    </div>
                                                    <div class="col-sm-2">
                                                        <div class="form-table table-responsive">
                                                            <table class="table table-bordered">
                                                                <tbody>
                                                                                   
                                                        <td>
                                                                <input class="form-control" type="text" [(ngModel)]="add.s_addr"required name="s_addr"
                                                            #s_addr="ngModel" >
                                                        </td>
                                                    </tbody>
                                                </table>
                                            </div>
                                                    </div>
                                                    <div class="col-sm-2">
                                                        <div class="form-table table-responsive">
                                                            <table class="table table-bordered">
                                                                <tbody>
                                                                                   
                                                        <td>
                                                                <input class="form-control" type="text" [(ngModel)]="add.s_addr"required name="s_addr"
                                                            #s_addr="ngModel" >
                                                        </td>
                                                    </tbody>
                                                </table>
                                            </div>
                                                    </div>
                                                    <div class="col-sm-2">                            
                                                        <div class="form-table table-responsive">
                                                            <table class="table table-bordered">
                                                                <tbody>                         
                                                        <td>
                                                                <input class="form-control" type="text" [(ngModel)]="add.s_addr"required name="s_addr"
                                                            #s_addr="ngModel" >
                                                        </td>
                                                    </tbody>
                                                </table>
                                            </div>
                                                    </div>
                                                    <div class="col-sm-2">
                                                        <div class="form-table table-responsive">
                                                            <table class="table table-bordered">
                                                                <tbody>                                                      
                                                        <td>
                                                                <input class="form-control" type="text" [(ngModel)]="add.s_addr"required name="s_addr"
                                                            #s_addr="ngModel" >
                                                        </td>
                                                    </tbody>
                                                </table>
                                            </div>
                                                    </div>
                                            </div>
                                          
                </form>
              
            </div>
        </div>
    </div>





      
   
           
            <div class="w_79" style="position: fixed;z-index: 99; ">
            
              <div class="tabs-header border-bottom">
                <ul>
                  <!-- <li> <a style="display: inline-flex;"> <span style="font-size: 23px; color:#4788ff" class="bx bx-group"></span> 
                    Student Master</a></li> -->
                        
                </ul>
              </div>
              <div class="right-corner">
    
                <div class="dropdown" style="display: inline-flex;">
    
                 <!--  <a class="dropdown-toggle   addbtn1" (click)="addReset()" data-toggle="tooltip" data-placement="bottom"
                  title="Add New">
    
                  <i class='bx bx-list-plus'></i>
                </a> -->
                <a class="dropdown-toggle  serch_icon   mshow"  data-toggle="tooltip" data-placement="bottom"
                title="search" >
               
                  <i style="    font-size: 20px;" class='bx bx-search'></i> 
                </a>
    
    
                <a  style="padding-left: 10px;padding-right: 10px;" class="dropdown-toggle " (click)="this.appservice.get_feescollection_Details(this.add.s_studentname)" data-toggle="tooltip" data-placement="bottom"
                title="Refresh">
    
                <i class='bx bx-rotate-left'></i>
              </a>
    
    
                  <button class="dropdown-toggle" type="button" data-toggle="dropdown" title="more" aria-haspopup="true"
                    aria-expanded="false">
                    <i class='bx bx-filter'></i>
                  </button>
    
        
    
                </div>
    
              </div>
      
            </div>
  
      
          <div class="col-md-12 nopad">
            <div class="spin_block" *ngIf="appservice.isload">
              <div class="d-flex justify-content-center">
                <div class="spinner-border text-primary" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            </div>
            <div class="m_2 res_table">
              <div class="search-bar_Ext mhide  show_bar " style="right: 120px;">
            
                <li  class="bx bx-left-arrow-alt mshow  search_back"></li>
      
                    <input  type="text"  (input)="dt.filterGlobal($event.target.value, 'contains')" 
                    class="search-form-control text"
                    placeholder="Search...">
        
              </div>
      

            <p-table responsiveLayout="scroll"#dt class="table table-striped custom-table" 
            [value]="appservice.Collection_Rows" 
            [globalFilterFields]="appservice.Student_Master_col_GF"
            selectionMode="single">
            <ng-template pTemplate="header">
                <tr>
                  <th>#</th>
                    <th *ngFor="let col of appservice.get_grid_fields_of('student_master')"
                    [ngStyle]="{'text-align': col.Align}"  [pSortableColumn]="col.field"
                    [style]="col.Style"  class="{{col.Class}}"    
                    style="white-space: pre">
                        {{col.Name}}
    
                    </th>
                    <th ></th>
                </tr>
    
    
             
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-car let-rowIndex="rowIndex">
                <tr [pSelectableRow]="rowData">
    
                  <td>{{rowIndex+1}}</td>
                    <td *ngFor="let col of appservice.get_grid_fields_of('student_master')"
                    [ngStyle]="{'text-align': col.Align}"  [pSortableColumn]="col.field" [style]="col.Style"
                     class="{{col.Class}}">
                        {{car[col.Field]}}
                    </td>
                 
                </tr>
            </ng-template>
        </p-table>
          
            </div>
          </div>
          <div class="col-md-12 nopad">
            <div class="w_79" style="  bottom: 0;
                    font-weight: 700;
                    color: #333333;
                    background-color: #f4f4f4;
                    padding: 0.571em 0.857em;
                    position: fixed;
                    bottom: 0;
                    border: 1px solid #c8c8c8;">
               <table style="width: 100%;">
                <tr>
                  <td style="color: darkslategray;">Total Items : {{appservice.length_of(dt)}} </td>
                  
                </tr>
              </table>
            </div>
          </div>

      
      
   <!--    <div class="row mshow">
        <div class="col-md-12 nopad">
          <div class="search-bar_Ext   disp" style="right: 50px;">
              
            <li style="color: #37517e;" (click)="appservice.back()" class="bx bx-left-arrow-alt mshow  search_back"></li>
            <label class="h_txex">Product Details</label>
      
      
                <div class="right-cornerN" >
                <div class="dropdown" style="display: inline-flex;">
                  
                  <a class="dropdown-toggle   addbtn1" (click)="addReset()" data-toggle="tooltip" data-placement="bottom"
                    title="Add New">
      
                    <i class='bx bx-list-plus'></i>
                  </a>
                  <a class="dropdown-toggle  serch_icon "  data-toggle="tooltip" data-placement="bottom"
                  title="search" >
                 
                    <i style="    font-size: 20px;" class='bx bx-search'></i> 
                  </a>
                  
                <a  style="padding-left: 10px;padding-right: 10px;" class="dropdown-toggle mshow "
                (click)="this.appservice.get_feescollection_Details(this.add.s_studentname)" data-toggle="tooltip" data-placement="bottom"
               title="Refresh">
      
               <i class='bx bx-rotate-left'></i>
             </a>
             <a  style="padding-top: 10px;padding-right: 10px;" class="dropdown-toggle  mhide"
             (click)="this.appservice.get_feescollection_Details(this.add.s_studentname)" data-toggle="tooltip" data-placement="bottom"
            title="Refresh">
            <i class='bx bx-rotate-left'></i>
          </a>
                  <button class="dropdown-toggle  mshow" type="button" data-toggle="dropdown" title="more" aria-haspopup="true"
                    aria-expanded="false">
                    <i class='bx bx-filter'></i>
                  </button>
      
            <div class="dropdown-menu" style="width: 325px;">
              <div style="background-color: teal; color: white; width: 100%; ">
                &nbsp;&nbsp;Search</div>
              <table>
                <tr>
                  <td> </td>
                  <td><button type="button" (click)="this.appservice.get_feescollection_Details(this.add.s_studentname)"
                      class="btn btn-info btn-sm">Search</button></td>
                </tr>
      
              </table>
      
      
              <div  class="search_Footer border-top" style="color: green;"> 
                <div (click)="export_excel(dt)"  class="buttons">
                  <span><li class="bx bx-download"></li></span>
                 <span> Export Excel</span>
                 
                </div>
      
               
                <div (click)="export_pdf(dt)"  class="buttons " style="color: red;">
                  <span><li class="bx bx-download"></li></span>
                 <span> Export PDF</span>
                 
                </div>
      
      
            </div>
           
      
      
            </div>
              
              </div>
              </div>
      
          </div>
      
          <div class="m_2 res_table">
            <div class="search-bar_Ext mhide  show_bar " style="right: 120px;">
          
              <li  class="bx bx-left-arrow-alt mshow  search_back"></li>
    
                  <input  type="text"  (input)="dt.filterGlobal($event.target.value, 'contains')" 
                  class="search-form-control text"
                  placeholder="Search...">
      
            </div>
          <p-table #dt class="table table-striped custom-table" 
          [value]="appservice.Collection_Rows" 
          [globalFilterFields]="appservice.Student_Master_col_GF"
          selectionMode="single">
          <ng-template pTemplate="header">
            <tr class="vs">
              <th>#</th>
                <th ></th>
               
                <th ></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-car let-rowIndex="rowIndex">
              <tr [pSelectableRow]="rowData">
    
                <td>{{rowIndex+1}}</td>
                <td> <span class="l1_txt">Item : {{rowData.pm_item_name}} </span>
                  <br><span class="l2_txt">Hsn : {{rowData.pm_hsn_code}} / GST : {{rowData.pm_gst_per}}  </span>
                  <br><span class="l3_txt">MRP : {{rowData.pm_mrpprice}}/ Pur_Price:{{rowData.pm_purprice}} </span>
                </td>
                
               
              </tr>
          </ng-template>
      </p-table>
        
          </div>
        </div>
        <div class="col-md-12 nopad">
          <div class="w_79" style="  bottom: 0;
                  font-weight: 700;
                  color: #333333;
                  background-color: #f4f4f4;
                  padding: 0.571em 0.857em;
                  position: fixed;
                  bottom: 0;
                  border: 1px solid #c8c8c8;">
             <table style="width: 100%;">
              <tr>
                <td style="color: darkslategray;">Total Items : {{appservice.length_of(dt)}} </td>
                
              </tr>
            </table>
          </div>
        </div>
          
      </div> -->
    
    
      
    
</div>