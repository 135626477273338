import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { Router, Event, NavigationEnd } from "@angular/router";
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { AppService } from 'src/app/app.service';
declare let $: any;

@Component({
    selector: "app-sidebar",
    templateUrl: "./sidebar.component.html",
    styleUrls: ["./sidebar.component.scss"],  
    encapsulation: ViewEncapsulation.None,
    
})
export class SidebarComponent implements OnInit {

  ac=18;
    constructor(private router: Router,public appservice:AppService) {

       
       
        router.events.subscribe((event: Event) => {
            if (event instanceof NavigationEnd) {
                $(".responsive-burger-menu").removeClass("active");
                $(".sidemenu-area").removeClass("active-sidemenu-area");
            }
        });
    }

    reloadCurrentRoute() {
        let currentUrl = this.router.url;
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
            this.router.navigate([currentUrl]);
        });
    }
    ngOnInit() {
        // Burger Menu JS
        $(".burger-menu").on("click", function() {
            $(this).toggleClass("active");
            $(".main-content").toggleClass("hide-sidemenu-area");
            $(".sidemenu-area").toggleClass("toggle-sidemenu-area");
            $(".top-navbar").toggleClass("toggle-navbar-area");
        });
        $(".responsive-burger-menu").on("click", function() {
            $(".responsive-burger-menu").toggleClass("active");
            $(".sidemenu-area").toggleClass("active-sidemenu-area");
        });
        this.reloadCurrentRoute();

        }
    
       
}
